import React from "react"

import Body from '../components/layout/default/main'

export default () => (
    <Body>
        <h1>Files</h1>
        <ul>
            <li>My Private Key</li>
        </ul>
    </Body>
)
