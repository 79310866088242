/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

// Style
import styles from "./content.module.css"

const Content = ({ children }) => {
    return (
        <div className={styles.content}>
            {children}
        </div>
    )
}

export default Content
