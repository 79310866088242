import React from "react"

import config from "../../data/config"

// Styles
import styles from "./footer.module.css"

const Footer = ({ children }) => {
    return (
        <footer className={styles.footer}>
            <div className={styles.contribute}>
                <h4>Want to contribute?</h4>
                <a href={`mailto:${config.contactEmail}`}>Suggest topics, tools, or questions!</a>
            </div>
            <div className={styles.copywrite}>
                © {new Date().getFullYear()} built and managed by
                {` `}
                <a href={`mailto:${config.contactEmail}`}>kade</a>
            </div>
        </footer>
    )
}

export default Footer
