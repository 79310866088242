/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"

import styles from "./menu.module.css"

const Menu = ({ children }) => {

    return (<StaticQuery
        query={graphql`
            query SiteMenuQuery {
                site {
                    siteMetadata {
                        menuLinks {
                            name
                            link
                        }
                    }
                }
            }
    `}
    render={data => (
        <React.Fragment>
          <div className={styles.menu}>
          {data.site.siteMetadata.menuLinks.map(link => (
                <li className={styles.menuitem} key={link.name}>
                  <Link to={link.link}>
                    {link.name}
                  </Link>
                </li>
              ))}
          </div>
        </React.Fragment>
      )}
    />
    )
}

export default Menu
