'use strict'

import React from "react"

// Components
import Footer from "../../footer/footer"
import Content from "./content"
import Menu from "../../menu/menu"

// Styles
import "./default.css"
import "typeface-russo-one"
import "@openfonts/nunito-sans_all"
import "@openfonts/roboto-mono_all"

const Body = ({ children }) => {
  return (
    <div>
      <Menu />
      <div className="paralax-container">
        <div className="content-container">
          <Content>{children}</Content>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Body
